import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import "bootstrap/dist/css/bootstrap.css"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Title = styled.h1`
  text-align: center;
`
const Description = styled.h2`
  text-align: center;
  color: #E85162;
`

const IndexPage = ({ data }) => (
  <Layout logo={data.logo.publicURL}>
    <SEO title="Home" />
    <Title>we are rebranding.</Title>
    <Description>stay tuned.</Description>
  </Layout>
)

export const logoQuery = graphql`
  query {
    logo: file(relativePath: { eq: "logo.svg" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
      publicURL
    }
  }
`

export default IndexPage
